.list {
  list-style: none;
}

.list-item {
  position: relative;
  margin-bottom: 1rem;
  line-height: 24px;
  padding-left: 1.5rem;
}

.list-item::before {
  position: absolute;
  content: "";
  left: 0;
  width: 10px;
  height: 10px;
  background-color: var(--color-headline);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}