.membership-headline--highlight {
  color: var(--color-headline);
}

.membership__staticstics {
  display: flex;
  justify-content: space-between;
}

.membership__statistics {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.membership__statistics img {
  max-width: 20%;
}

@media (max-width: 1000px) {
  .membership__statistics img {
    max-width: 40%;
  }
}

.membership__numbers {
  display: flex;
  font-size: 22px;
  margin: 1rem 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.membership__numbers-wrapper {
  display: flex;
  align-items: center;
  max-width: 33%;
  min-width: 23rem;
  margin-bottom: 1rem;
}

@media (max-width: 1000px) {
  .membership__numbers-wrapper {
    min-width: 100%;
  }
}

.membership__number {
  font-size: 36px;
  white-space: nowrap;
  margin-right: 1rem;
}

.membership__number--one {
  color: var(--color-headline);
}

.membership__number--two {
  color: var(--color-link);
  margin-left: 1rem;
}

.membership__number--three {
  color: var(--color-tertiary);
  margin-left: 1rem;
}

.membership__result {
  padding-top: 6rem;
}

@media (max-width: 600px) {
  .membership__result {
    display: none;
  }
}
