.footer {
  position: relative;
  padding: 3rem 6rem;
}

@media (max-width: 1200px) {
  .footer {
    padding: 3rem 4%;
  }
}

.footer__inner {
  max-width: 1130px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.footer__icon {
  margin-left: 0.5rem;
  stroke: currentColor;
  fill: currentColor;
}

.footer__link {
  color: grey;
}

.footer__link:active,
.footer__link:focus {
  color: var(--color-dark);
}

@media (hover: hover) {
  .footer__link:hover {
    color: var(--color-dark);
  }
}

@media (max-width: 600px) {
  .footer__inner {
    flex-direction: column;
  }
}
