.link {
  color: var(--color-link);
  transition: color 0.2s linear;
}

.link:active,
.link:focus {
  color: var(--color-dark);
}

@media (hover: hover) {
  .link:hover {
    color: var(--color-dark);
  }
}
