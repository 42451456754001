:root {
  --font-family-headline: "Prompt", Roboto, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-family-text: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

  --color-primary: #dbe5e7;
  --color-secondary: #ecdbc3;
  --color-font: #393939;
  --color-headline: #77959b;
  --color-link: #c78427;
  --color-tertiary: #95ad95;
  --color-bg-red: #fcf0e9;
  --color-bg-blue: var(--color-primary);
  --color-bg-mint: #e7ecec;
  --color-bg-green: #e5f0e5;
}

.header {
  padding: 3rem 6rem 0;
  margin-bottom: 3rem;
}

@media (max-width: 1200px) {
  .header {
    padding: 4% 4% 0;
    margin-bottom: 3rem;
  }
}

.header__inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .header__inner {
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
  }
}

.logo {
  color: var(--color-font);
  text-decoration: none;
  font-family: var(--font-family-headline);
  font-size: 20px;
  font-weight: 200;
}

.logo:focus {
  outline: 1px dashed var(--color-font);
}

.nav__link {
  font-weight: 200;
  font-size: 18px;
  font-family: var(--font-family-headline);
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-font);
  margin-left: 3rem;
  transition: color 0.2s linear;
}

@media (max-width: 600px) {
  .nav__link {
    margin-top: 1rem;
    margin-left: 2rem;
  }

  .nav__link:first-of-type {
    margin-left: 0;
  }
}

.nav__link:active,
.nav__link:focus,
.nav__link--active {
  color: var(--color-link);
}

@media (hover: hover) {
  .nav__link:hover {
    color: var(--color-link);
  }
}

.nav__link--active {
  text-decoration: underline;
}

.header__scroll-top {
  position: fixed;
  right: 8rem;
  bottom: 15vh;
  border-radius: 50%;
  padding: 10px 12px 8px;
  fill: currentColor;
  z-index: 1;
}

@media (max-width: 1200px) {
  .header__scroll-top {
    right: 6%;
  }
}
