.process {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 600px) {
  .process {
    
  }
}

.process__item {
  display: inline-flex;
  flex-direction: column;
  max-width: 7rem;
  margin: 1rem 1rem 1rem 0;
  text-align: center;
  align-items: center;
}

.process__icon {
  margin-bottom: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: var(--color-headline);
}

.process__icon svg {
  width: 2rem;
  height: 2rem;
  stroke: currentColor;
  fill: currentColor;
}
