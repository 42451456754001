.dataset {
  list-style: none;
  width: 100%;
}

.dataset__item {
  display: inline-flex;
  flex-direction: column;
  width: 13rem;
  margin: 1rem 1rem 1rem 0;
}

.dataset__title {
  padding-bottom: 1rem;
}
