.headline {
  font-weight: 200;
  line-height: 1;
  margin-bottom: 1rem;
  word-break: break-word;
}

.headline--h1 {
  font-size: 64px;
  text-transform: uppercase;
  font-family: var(--font-family-headline);
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .headline--h1 {
    font-size: 48px;
  }
}

.headline--h2 {
  font-size: 32px;
  margin-bottom: 2rem;
}

.headline--h3 {
  font-size: 24px;
  margin-bottom: 1rem;
}

.headline--h4 {
  font-weight: 400;
}

.headline--spaceBefore {
  margin-top: 2rem;
}
