@media (max-width: 600px) {
  .home__header-image {
    display: none;
  }
}

.home__header-mobile-image {
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 601px) {
  .home__header-mobile-image {
    display: none;
  }
}

.home__explore {
  margin: 4rem 0 2rem;
}

.home__headline {
  color: var(--color-headline);
}

.home__button {
  margin: 0 1rem 1rem 0;
}

.home__feature-subtitle {
  font-size: 16px;
}

.home__portrait {
  width: 20rem;
  height: 20rem;
  background-color: red;
  flex-shrink: 0;
  margin-left: 2rem;
}

.home__supporter,
.home__share,
.home__pizza {
  min-height: 460px;
}

@media (max-width: 800px) {
  .home__portrait {
    display: none;
  }
  .home__supporter,
  .home__share,
  .home__pizza {
    min-height: auto;
  }
}

@media (min-width: 601px) {
  .home__supporter {
    background-image: url("./assets/supporter.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -23rem center;
  }
}

@media (min-width: 1200px) {
  .home__supporter {
    background-size: contain;
    background-position: left center;
  }
}

@media (min-width: 601px) {
  .home__supporter .section__content-wrapper,
  .home__share .section__content-wrapper,
  .home__pizza .section__content-wrapper {
    max-width: 50%;
  }
}

@media (min-width: 601px) {
  .home__share {
    background-image: url("./assets/share.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50vw center;
  }
}

@media (min-width: 601px) {
  .home__pizza {
    background-image: url("./assets/pizza.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -29rem center;
  }
}

@media (min-width: 970px) {
  .home__pizza {
    background-size: contain;
    background-position: -10rem center;
  }
}

@media (min-width: 1200px) {
  .home__pizza {
    background-size: contain;
    background-position: -5rem center;
  }
}

.home__contact {
  display: flex;
  align-items: center;
  margin-top: -2rem;
}

@media (max-width: 600px) {
  .home__contact {
    display: block;
    margin-top: 0;
  }

  .home__contact-mail {
    display: none;
  }
}

.home__contact-mail {
  color: var(--color-bg-blue);
  fill: currentColor;
  flex-shrink: 0;
  height: 13rem;
  width: 11rem;
  margin-right: 1rem;
}

.home__email {
  display: flex;
  align-items: center;
}

.home__email-icon {
  color: var(--color-font);
  fill: currentColor;
  flex-shrink: 0;
  margin-right: 0.5rem;
}
