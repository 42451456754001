.button {
  font-size: 16px;
  border-radius: 2rem;
  padding: 12px 2.5rem;
  text-decoration: none;
  color: var(--color-font);
  display: inline-block;
  font-weight: 400;
  font-family: var(--font-text);
  white-space: nowrap;
  transition: color 0.2s linear, background-color 0.2s linear;
  cursor: pointer;
}

.button--primary {
  background-color: var(--color-primary);
}

.button--primary:active,
.button--primary:focus {
  background-color: #a5bec3;
}

@media (hover: hover) {
  .button--primary:hover {
    background-color: #a5bec3;
  }
}

.button--secondary {
  background-color: var(--color-secondary);
}

.button--secondary:active,
.button--secondary:focus {
  background-color: #d7b381;
}

@media (hover: hover) {
  .button--secondary:hover {
    background-color: #d7b381;
  }
}

.button--outline {
  border: 1px solid var(--color-font);
  background-color: transparent;
}

.button--outline:active,
.button--outline:focus {
  background-color: var(--color-font);
  color: #fff;
}

@media (hover: hover) {
  .button--outline:hover {
    background-color: var(--color-font);
    color: #fff;
  }
}
