.gallery {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .gallery {
    flex-direction: column;
  }

  .gallery__button:first-child {
    margin-bottom: 1rem;
  }

  .gallery__button:last-child {
    margin-top: 1rem;
  }
}

.gallery__inner {
  margin: 0 1rem;
}

@media (max-width: 600px) {
  .gallery__inner {
    margin: 0 0.5rem;
  }
}

.gallery__image {
  width: 100%;
}

.gallery__button {
  border-radius: 50%;
  padding: 10px 12px 8px;
  fill: currentColor;
  width: 50px;
  height: 50px;
}

@media (max-width: 600px) {
  .gallery__button {
    padding: 4px;
    width: 35px;
    height: 35px;
  }
}