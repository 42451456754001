.video {
  width: 100%;
  position: relative;
}

.video__file {
  width: 67%;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
}

.video__file--mobile {
  width: 71%;
  top: 10px;
}

.video__frame {
  width: 100%;
  position: relative;
}

@media (max-width: 600px) {
  .video__frame {
    width: 80%;
  }
}
