.membership-headline--highlight {
  color: var(--color-headline);
}

.share__prototype {
  display: flex;
}

.share__prototype__step {
  max-width: 26%;
}

.share__arrow {
  max-width: 11%;
  padding: 0 20px;
}

@media (max-width: 600px) {
  .share__prototype {
    display: block;
  }

  .share__arrow {
    display: none;
  }

  .share__prototype__step {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}
