body {
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */
  -moz-text-size-adjust: 100%; /* Prevent font scaling in landscape */
  -ms-text-size-adjust: 100%; /* Prevent font scaling in landscape */
  text-size-adjust: 100%; /* Prevent font scaling in landscape */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: var(--font-family-text);
  color: var(--color-font);
  font-size: 18px;
  font-weight: 300;
}

main {
  min-height: calc(100vh - 78px - 6rem - 136px);
}

::-moz-selection {
  color: #fff;
  background-color: var(--color-headline);
}

::selection {
  color: #fff;
  background-color: var(--color-headline);
}

* {
  -webkit-tap-highlight-color: transparent;
  border-color: transparent;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
select,
textarea,
button,
a,
*:focus {
  outline: 1px solid transparent;
}

input::-ms-clear {
  display: none;
}

ul {
  list-style: none;
}

button::-moz-focus-inner {
  border-color: transparent;
}

b,
strong {
  font-weight: 500; /* Was still 400 in Firefox */
}

main,
header,
footer,
section,
nav,
aside,
details,
summary,
article,
time {
  display: block;
}
