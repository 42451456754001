.projectSwitch__wrapper {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .projectSwitch__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .projectSwitch__button:first-child {
    margin-bottom: 1rem;
  }
}

.projectSwitch__button {
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
}

.projectSwitch__button:hover svg {
  fill: white;
}

.projectSwitch__button--previous {
  margin-right: 0.5rem;
}

.projectSwitch__button--next {
  margin-left: 0.5rem;
}
