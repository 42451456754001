.about__date {
  color: var(--color-link);
}

.about__tag {
  background-color: var(--color-secondary);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: inline-block;
  white-space: nowrap;
}

.about__tag--secondary {
  background-color: var(--color-primary);
}

.about__skills {
  display: flex;
}

@media (max-width: 600px) {
  .about__skills {
    flex-direction: column;
  }
}

.about__skill-headline {
  margin-bottom: 1.5rem;
}

.about__learnings {
  white-space: nowrap;
  margin-left: 1rem;
}

@media (max-width: 1000px) {
  .about__learnings {
    white-space: initial;
  }
}

@media (max-width: 600px) {
  .about__learnings {
    margin-left: 0;
  }
}

.about__certificates {
  display: flex;
  flex-wrap: wrap;
}

.about__certificate {
  width: 33%;
  padding: 0 3rem 2rem 0;
  flex-direction: column;
}

@media (max-width: 800px) {
  .about__certificate {
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .about__certificates {
    display: block;
  }

  .about__certificate {
    display: block;
    padding: 0 0 2rem;
    width: 100%;
    max-width: 100%;
  }
}

.about__header-mobile-image {
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (min-width: 601px) {
  .about__header-mobile-image {
    display: none;
  }
}

@media (max-width: 600px) {
  .about__header-image {
    display: none;
  }
}
