.contact__headline {
  color: var(--color-headline);
}

.contact__link--LI {
  color: #2867b2;
}

.contact__link--xing {
  color: #026466;
}

.contact__icon {
  stroke: currentColor;
  fill: currentColor;
  margin-right: 0.5rem;
}

.contact__icon:hover {
  color: var(--color-font);
}

.contact__social {
  margin-top: 3rem;
}
