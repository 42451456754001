.bootcamp--highlight {
  color: var(--color-headline);
}

.bootcamp__journeys {
  max-width: 100%;
}

.bootcamp__journey__names {
  display: flex;
  justify-content: space-between;
}

.bootcamp__result {
  display: flex;
}

.bootcamp__result__step {
  max-width: 20%;
}

.bootcamp_arrow {
  max-width: 20%;
  padding: 0 20px;
}

.bootcamp__process-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media (max-width: 600px) {
  .bootcamp__result {
    display: block;
  }

  .bootcamp__result__step {
    max-width: 100%;
  }
  .bootcamp__process-row {
    flex-direction: column;
  }
}

.bootcamp__process-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 13rem;
  text-align: center;
}

.bootcamp__icon {
  color: var(--color-headline);
  width: 2rem;
  height: 2rem;
  stroke: currentColor;
  fill: currentColor;
}

.bootcamp__icon--people {
  color: var(--color-link);
  margin: 2rem;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
}

@media (max-width: 600px) {
  .bootcamp__icon--people {
    display: none;
  }
}
