.section {
  padding: 0 6rem;
  margin-bottom: 6rem;
}

@media (max-width: 1200px) {
  .section {
    padding: 0 4%;
    margin-bottom: 4rem;
  }
}

.section--noMargin {
  margin-bottom: 0;
}

.section--blue {
  background-color: var(--color-bg-blue);
}

.section--mint {
  background-color: var(--color-bg-mint);
}

.section--green {
  background-color: var(--color-bg-green);
}

.section--anthrazit {
  background-color: var(--color-font);
  color: var(--color-bg-red);
}

.section__content {
  width: 100%;
  max-width: 1130px;
  margin: 0 auto;
}

.section__content--columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .section__content--columns {
    flex-direction: column;
    align-items: center;
  }
}

.section__content--right {
  display: flex;
  justify-content: flex-end;
}

.section__content--centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section__content--reducedWidth > * {
  max-width: 48rem;
}

.section__content--center {
  text-align: center;
}

.section__content-wrapper {
  padding: 6rem 0;
}

.section__content-wrapper--image {
  max-width: 50%;
}

@media (max-width: 600px) {
  .section__content-wrapper--image {
    max-width: 100%;
  }

  .section__content-wrapper {
    max-width: none;
    padding: 4rem 0;
  }

  .section__content--columns .section__content-wrapper {
    padding: 4rem 0 0 0;
  }
}

.section__image {
  max-width: 50%;
  height: auto;
  margin-left: 4rem;
}

@media (min-width: 1200px) {
  .section__image--intro {
    max-width: 60%;
  }

  .section__content--intro {
    align-items: flex-end;
  }
}

.section__image--right {
  order: -1;
  margin: 0 4rem 0 0;
}

@media (max-width: 600px) {
  .section__image {
    margin: 0;
    max-width: 100%;
    order: initial;
    margin-top: 1rem;
  }
}

.section__image-fallback {
  width: 100%;
  height: auto;
}
