.paragraph {
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 24px;
}

.paragraph--centered {
  text-align: center;
}

.paragraph--bold {
  font-weight: normal;
}
